import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

export const StatsWrapper = styled.div`
	z-index: 1;
	overflow: hidden;
	background-color: var(--primary-neutral-white);
	margin: 5rem 0;
	position: relative;

	.stats-grid-heading {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		margin-bottom: 1rem;
	}
	.course-line-graphic {
		z-index: -1;
	}
	.slider {
		width: 170%;
		& .slick-dots {
			width: calc(100vw - 40px);
			bottom: -32px;
			li button:before {
				font-size: 1.875rem;
			}
		}
	}

	.stats-action {
		margin: 2.5rem 0;
		z-index: 1;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.stats-action {
			width: 100%;
			margin: 3.5rem 0 0;
		}
		.slick-current [class*="StatsItemWrapper"] {
			padding: 2rem 1rem;
		}
		.slick-track {
			display: flex;
			align-items: center;
		}
		.slick-slide {
			margin-right: 2rem;
		}
		.stats-grid-heading {
			grid-column: span 6;
		}
	}
`;
export const StatsItemsWrapper = styled.div`
	display: flex;

	justify-content: ${(props) =>
		props.statsCount <= 2 ? `flex-start` : `space-between`};
	gap: 4rem;
	flex-wrap: wrap;
	z-index: 1;
	background-color: var(--primary-neutral-white);

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		background: var(--secondary-gray-x-light);
		overflow: hidden;
		flex-wrap: nowrap;
		gap: 2rem;
		padding: 14px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;

export const StatsItemWrapper = styled.div`
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	min-width: 210px;
	max-width: 295px;
	flex: 1 0 20%;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		min-width: auto;
		max-width: none;
	}
`;

export const StatsItemSubDivWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
`;
