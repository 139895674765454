import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// components
import {
	Text,
	Grid,
	Col,
	Button,
	LineGraphic,
} from "@website-builder/ui/shared/elements";

//styles
import {
	StatsWrapper,
	StatsItemsWrapper,
	StatsItemWrapper,
	StatsItemSubDivWrapper,
} from "./styles";

//constants
import {
	TABLET_M_BP,
	MOBILE_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { Slider } from "@website-builder/ui/shared/elements";

const StatsWR = ({
	label,
	headline,
	statsItem,
	cta,
	globalVariableWithURL,
	pageSection,
}) => {
	const isB2U = isBusinessVerticalB2U();
	// screen size variables
	const satisfiesTabletWidth = useMediaQuery(`(max-width:${TABLET_M_BP}px)`);
	const [isTablet, setIsTablet] = useState(undefined);

	useEffect(() => {
		setIsTablet(satisfiesTabletWidth);
	}, [satisfiesTabletWidth]);
	// checks for mobile width
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${MOBILE_M_BP}px)`);
	const [isMobile, setIsMobile] = useState(undefined);

	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const StatItem = (item, globalVariableWithURL) => {
		const description = richTextResolver(
			item.description,
			globalVariableWithURL,
		);
		return (
			<StatsItemWrapper>
				<StatsItemSubDivWrapper>
					<Text
						variant="label_M"
						color={{
							base: "secondary",
							color: "gray",
							shade: "dark",
						}}
					>
						{item?.heading}
					</Text>
					<Text
						variant="headline_1"
						tag="p"
						color={{
							base: "secondary",
							color: "blue",
							shade: "300",
						}}
					>
						{item?.statValue}
					</Text>
				</StatsItemSubDivWrapper>
				<StatsItemSubDivWrapper
					style={{
						display: "flex",
						gap: "0.5rem",
						flexDirection: "column",
					}}
				>
					<Text
						variant="paragraph_M"
						color={{
							base: "primary",
							color: "neutral-nightshade",
							shade: "600",
						}}
						tag="div"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
					{item?.date ? (
						<Text
							variant="paragraph_S"
							color={{
								base: "secondary",
								color: "gray",
								shade: "dark",
							}}
						>
							{item?.date}
						</Text>
					) : null}
				</StatsItemSubDivWrapper>
			</StatsItemWrapper>
		);
	};

	// renders all the stat items
	const renderStatItems = () => {
		const otherSettings = {
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: false,
			dots: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 990,
					settings: {
						dots: true,
						infinite: true,
						slidesToShow: 2,
						slidesToScroll: 1,
						autoplay: false,
						speed: 500,
						arrows: false,
						appendDots: (dots) => {
							return <ul>{dots}</ul>;
						},
					},
				},
			],
		};
		if (isTablet) {
			return (
				<Slider
					carouselData={statsItem.map((item, index) => {
						return (
							<StatsItemsWrapper statsCount={statsItem?.length}>
								<StatItem
									{...item}
									key={`${item?.name}+${index}`}
									globalVariableWithURL={globalVariableWithURL}
								/>
							</StatsItemsWrapper>
						);
					})}
					otherSettings={otherSettings}
				/>
			);
		}
		return (
			<StatsItemsWrapper statsCount={statsItem?.length}>
				{statsItem.map((item, index) => {
					return (
						<StatItem
							{...item}
							key={`${item?.name}+${index}`}
							globalVariableWithURL={globalVariableWithURL}
						/>
					);
				})}
			</StatsItemsWrapper>
		);
	};

	return (
		<StatsWrapper>
			<Grid className="stats-grid">
				<Col start={2} span={10} mdSpan={5} className="stats-grid-heading">
					{label ? (
						<Text
							variant="label_M"
							color={{
								base: "secondary",
								color: "gray",
								shade: "dark",
							}}
						>
							{label}
						</Text>
					) : null}

					<Text
						variant="headline_3"
						tag="h2"
						color={{
							base: "primary",
							color: "neutral-nightshade",
							shade: "600",
						}}
					>
						{headline}
					</Text>
				</Col>
				<Col start={2} span={10} mdSpan={6}>
					{renderStatItems()}
					{cta && (
						<Button
							{...cta}
							buttonConfiguration={isB2U && cta}
							className="stats-action"
							type={cta?.type}
							onClick={() =>
								handleCTAClick(cta, {
									page_section: pageSection,
									redirect_to: cta?.link?.link,
									click_text: cta?.buttonText,
								})
							}
							buttonText={cta?.buttonText}
						></Button>
					)}
				</Col>
			</Grid>
			<LineGraphic
				className="course-line-graphic"
				stroke="var(--primary-green-600)"
				size={isMobile ? "80vw" : isTablet ? "45vw" : "30vw"}
				right="0"
				bottom="0"
				x="-40%"
				y="-45%"
				rotate="120"
				line="double_switch"
			/>
		</StatsWrapper>
	);
};

export default StatsWR;
